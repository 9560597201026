import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import {
  putEditProvidersCourse,
  putEditSellersCourse,
} from '../../../services/api';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';
import Input from '../../forms/Input';
import Modal from '../Modal';
import { Both } from '../ModalEditItem/styles';
import { Bottom, Container, Row } from './styles';
import { saveManageCoursesLog } from '../../../services/logs';

interface ICourseEdit {
  name: string;
  id: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  clearFilter: () => void;
  course: ICourseEdit;
}

const ModalEditCourse: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  clearFilter,
  course,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (user.type === 'seller' || user.type === 'seller_content_manager') {
          await putEditSellersCourse(course.id, data.name);
          clearFilter();
        }

        if (user.type === 'provider') {
          await putEditProvidersCourse(course.id, data.name);
          clearFilter();
        }

        saveManageCoursesLog({
          userId: user.id,
          action: 'updated_course',
          courseId: course.id,
        });

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Curso editado editado com sucesso!',
        });

        setIsOpen();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, course.id, setIsOpen, clearFilter, user.type],
  );
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>Editar Curso</h1>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={course}>
          <Row>
            <Input
              name="name"
              label="Nome do curso:"
              type="text"
              defaultValue={course.name}
            />
          </Row>
          <Both />
          <button type="submit" hidden>
            submeter
          </button>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalEditCourse;
