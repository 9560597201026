import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';
import Input from '../../forms/Input';
import ReactSelectForm from '../../forms/ReactSelectForm';
import Modal from '../Modal';
import { saveCreateNewUserLog } from '../../../services/logs';

import {
  postCreateContentManager,
  postCreateEditor,
  postCreateUser,
} from '../../../services/api';

import { Container, Bottom, RowGroup, Row, Both } from './styles';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  userType: {
    value: string;
    label: string;
  }[];
  handleInitialState(): void;
}

const ModalAddUser: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  userType,
  handleInitialState,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Campo Obrigatório'),
          email: Yup.string()
            .required('Campo Obrigatório')
            .email('Email inválido'),
          type: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (user.type === 'provider') {
          await postCreateEditor({
            email: data.email,
            nameUser: data.name,
            password: '123456',
            providerId: user.provider?.id,
          });
        } else if (user.type === 'seller') {
          await postCreateContentManager({
            email: data.email,
            name: data.name,
            type: data.type,
          });
        } else {
          await postCreateUser({
            email: data.email,
            name: data.name,
            password: '123456',
            type: data.type,
            status: 'active',
          });
        }

        saveCreateNewUserLog({
          userId: user.id,
          newUserEmail: data.email,
        });

        addToast({
          title: 'Usuário cadastrado com sucesso!',
          type: 'success',
          description: 'Um email foi enviado para este usuário',
        });

        handleInitialState();
        setIsOpen();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setIsOpen, handleInitialState, user.provider, user.type],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>Novo usuário</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <RowGroup>
            <Input name="name" label="Nome:" placeholder="John Doe" />
            <ReactSelectForm
              name="type"
              options={[{ value: '', label: 'Todos os Usuário' }, ...userType]}
              placeholder="Todos os usuários"
            />
          </RowGroup>
          <Both />
          <Row>
            <Input
              name="email"
              label="Email:"
              placeholder="email@dominio.com.br"
            />
          </Row>
          <button type="submit" hidden>
            submeter
          </button>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalAddUser;
