import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import Checkbox, { CheckBoxAll } from '../../components/forms/Checkbox';
import ChoiceEllipse from '../../components/forms/ChoiceEllipse';
import InputSearch from '../../components/forms/InputSearch';
import ModalAddUser from '../../components/template/ModalAddUser';
import ModalCsv from '../../components/template/ModalCsv';
import Tabs, { TabItem } from '../../components/template/Tabs';
import { useAuth } from '../../hooks/auth';
import {
  deleteUserSeller,
  filterUsers,
  getProviderTeam,
  getTeamSeller,
  getUsersFilter,
  putChangeStatusUser,
} from '../../services/api';

import {
  IconDownload,
  IconPersonAdd,
  IconTrash,
  IconUserCircle,
  IconDelete,
  IconEdit,
  IconCrown,
  IconRotate,
} from '../../styles/icons';

import { Container, Content, Actions, ActionsContent } from './styles';
import { usersTypes, permissionUsersTypes } from '../../data/users';
import { useToast } from '../../hooks/toast';
import AlertConfirm from '../../components/template/AlertConfirm';
import useLazyEffect from '../../hooks/useLazyEffect';
import ModalEditUser from '../../components/template/ModalEditUser';
import {
  AddButton,
  Bagde,
  DeleteButton,
  ImportButton,
  NameContent,
  OptionLeft,
  OptionRight,
  Options,
  TableContent,
  Title,
  TooltipInfo,
} from '../../components/template/TemplateManager/styles';
import ReactSelect from '../../components/forms/ReactSelect';
import clearObject from '../../utils/clearObject';
import { saveManageUserLog } from '../../services/logs';

interface IUsers {
  id: string;
  name: string;
  email: string;
  status: string;
  type: string;
  unit: string;
  createdAt: string;
  updatedAt: string;
}

interface IEditUser {
  id: string;
  name: string;
  type: string;
  email: string;
}

interface IFilterInternalTeam {
  name: string;
  email: string;
  providerId: string;
  status: 'active' | 'inative' | 'exclused' | 'pending' | 'draft';
  orderName: string;
  orderDate: string;
  type: [
    'admin',
    'director',
    'manager',
    'curator',
    'review',
    'seller',
    'client',
    'user',
    'provider',
    'serviceProvider',
  ];
}

const userStatus = [
  { id: 'active', label: 'Ativo' },
  { id: 'inative', label: 'Inativo' },
  { id: 'exclused', label: 'Excluído' },
];

const Users: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  // const firstLoad = useRef(false);

  const [users, setUsers] = useState<IUsers[]>([]);
  const [checks, setChecks] = useState<Array<string>>([]);

  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('tab-02');
  const [currentUserId, setCurrentUserId] = useState<IEditUser>({
    id: '',
    email: '',
    name: '',
    type: '',
  });

  const [select, setSelect] = useState('all');
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [tabFilter, setTabFilter] = useState<Partial<IFilterInternalTeam>>();

  // const [search, setSearch] = useState<Partial<IFilterInternalTeam>>();

  const [modalAddUserOpen, setModalAddUserOpen] = useState(false);
  const [modalImportCsvOpen, setModalImportCsvOpen] = useState(false);
  const [modalEditUserOpen, setModalEditUserOpen] = useState(false);

  // Identify label userType
  const userType = useMemo(() => {
    let userTeamId;

    switch (user.type) {
      case 'admin':
        userTeamId = 'admin';
        break;

      case 'director':
        userTeamId = 'director';
        break;

      case 'seller':
        userTeamId = 'seller';
        break;

      default:
        userTeamId = 'provider';
        break;
    }

    return permissionUsersTypes[userTeamId].map(item => ({
      label: item.name,
      value: item.nameSlug,
    }));
  }, [user.type]);

  // First load
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);

        if (user.type === 'seller' || user.type === 'seller_assistent') {
          const { data } = await getTeamSeller();

          setUsers(
            data.map(userRes => {
              return {
                id: userRes.user.id,
                name: userRes.user.name,
                email: userRes.user.email,
                type: userRes.user.type,
                status: userRes.status,
              };
            }),
          );
        } else if (user.type === 'provider') {
          const { data: res } = await getProviderTeam();
          setUsers(res);
        } else if (userType[0].value === 'serviceProvider') {
          userType[0].value = 'provider';
          const { data: res } = await filterUsers({
            orderDate: 'DESC',
            providerId: user.provider?.id,
          });
          setUsers(res);
        } else {
          console.log(userType);
          const { data: res } = await filterUsers({
            orderDate: 'DESC',
            type: userType.map(item => item.value),
            providerId: user.provider?.id,
          });

          setUsers(res);
        }
      } catch {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }

      setLoading(false);
    };

    load();
  }, [addToast, userType, user.provider, user.type]);

  // filters
  useLazyEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        if (user.type === 'seller' || user.type === 'seller_assistent') {
          const { data: res } = await getTeamSeller();
          setUsers(
            res.map(userRes => {
              return {
                status: userRes.status,
                name: userRes.user.name,
                id: userRes.user.id,
                email: userRes.user.email,
                type: userRes.user.type,
              };
            }),
          );
        } else if (user.type === 'provider') {
          const { data: res } = await getProviderTeam();
          setUsers(res);
        } else if (user.type === 'admin' || user.type === 'director') {
          const { data } = await getUsersFilter(
            clearObject({
              status: status !== 'all' ? status : undefined,
              name,
              type:
                select !== 'all' ? select : userType.map(item => item.value),
              ...tabFilter,
              idProvider: user.provider?.id,
            }) as IFilterInternalTeam,
          );
          setUsers(data);
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo de inesperado aconteceu',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [select, tabFilter, status, name]);

  // Change Tabs
  useLazyEffect(() => {
    const load = async (): Promise<void> => {
      let filter: Partial<IFilterInternalTeam>;

      switch (currentTab) {
        case 'tab-01':
          filter = {
            orderName: 'ASC',
          };

          break;
        case 'tab-02':
          filter = {
            orderDate: 'DESC',
          };
          break;
        default:
          filter = {
            status: 'exclused',
          };
          break;
      }

      setTabFilter(filter);
    };
    load();
  }, [currentTab, addToast]);

  const handleCheck = useCallback(check => {
    setChecks(check);
  }, []);

  const handleTab = useCallback(
    (id: string) => {
      if (currentTab !== id) setLoading(true);
      setCurrentTab(id);
    },
    [currentTab],
  );

  const handleInitialState = useCallback(async () => {
    try {
      setLoading(true);
      setCurrentTab('tab-02');
      setSelect('all');
      setStatus('all');
      setChecks([]);
      setCurrentUserId({
        id: '',
        email: '',
        name: '',
        type: '',
      });

      if (user.type.includes('seller')) {
        const { data } = await getTeamSeller();
        const parser = data.map(userRes => ({
          status: userRes.status,
          name: userRes.user.name,
          id: userRes.user.id,
          email: userRes.user.email,
          type: userRes.user.type,
        }));

        setUsers(parser);
      } else if (user.type === 'provider') {
        const { data } = await getProviderTeam();
        setUsers(data);
      } else if (user.type === 'admin' || user.type === 'director') {
        const { data } = await filterUsers({
          orderDate: 'DESC',
          type: userType.map(item => item.value),
        });

        setUsers(data);
      }
    } catch (error) {
      addToast({
        title: 'Ops...',
        type: 'error',
        description: 'Algo de inesperado aconteceu',
      });
    }

    setLoading(false);
  }, [addToast, user.type, userType]);

  const handleChangeUserStatus = useCallback(
    async ({ ids, statusUser }: { ids: Array<string>; statusUser: string }) => {
      const confirm = await AlertConfirm({
        title: `Tem certeza que deseja alterar o status do usuário para ${
          userStatus.find(item => item.id === statusUser)?.label
        }?`,
        text: 'Essa ação pode ser desfeita',
      });

      if (!confirm) {
        return;
      }

      try {
        if (user.type === 'seller') {
          const promisses: Promise<any>[] = [];
          ids.map(id => promisses.push(deleteUserSeller(id)));
          await Promise.all(promisses);
        } else {
          await putChangeStatusUser({
            ids,
            status: statusUser,
          });
        }

        await saveManageUserLog({
          userId: user.id,
          action: 'update_user_status',
          status: statusUser,
          modifiedUserEmail: null,
          modifiedUserId: ids,
        });

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Status editado com sucesso!',
        });

        handleInitialState();
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo de inesperado aconteceu, tente mais tarde.',
        });
      }
    },
    [addToast, handleInitialState, user.type],
  );

  const handleSelect = useCallback((value: string) => {
    setSelect(value);
  }, []);

  const handleStatus = useCallback((value: string) => {
    setStatus(value);
  }, []);

  const toggleModalAddUser = useCallback(() => {
    setModalAddUserOpen(!modalAddUserOpen);
  }, [modalAddUserOpen]);

  const toggleModalImportCsv = useCallback(() => {
    setModalImportCsvOpen(!modalImportCsvOpen);
  }, [modalImportCsvOpen]);

  const toggleCurrentUserId = useCallback(() => {
    setModalEditUserOpen(!modalEditUserOpen);
  }, [modalEditUserOpen]);

  const handleCurrentUserId = useCallback(
    (editUser: IEditUser) => {
      setCurrentUserId(editUser);
      toggleCurrentUserId();
    },
    [toggleCurrentUserId],
  );

  const renderUsers = useCallback(() => {
    return (
      <table>
        <thead>
          <tr className="no-cursor no-hover">
            <th>
              <CheckBoxAll
                ids={users.map(item => item.id)}
                handleCheckAll={handleCheck}
              />
            </th>
            <th>Usuário</th>
            <th style={{ fontStyle: 'italic' }}>Status</th>
            <th>Tipo</th>
            {(user.type === 'admin' ||
              user.type === 'director' ||
              user.type === 'seller') && <th>Ações</th>}
          </tr>
        </thead>
        <tbody>
          {users.length <= 0 && (
            <tr>
              <td
                colSpan={
                  user.type === 'admin' || user.type === 'director' ? 5 : 4
                }
              >
                <p style={{ textAlign: 'center' }}>
                  Nenhum item encontrado{' '}
                  <span role="img" aria-label="wow">
                    😮
                  </span>
                </p>
              </td>
            </tr>
          )}
          {users.map(userItem => (
            <tr key={`${userItem.id}-tab-01`} className="no-cursor">
              <td>
                <Checkbox
                  name="check"
                  handleCheck={handleCheck}
                  checks={checks}
                  id={`${userItem.id}`}
                />
              </td>
              <td>
                <NameContent>
                  <IconUserCircle />
                  <span>{userItem.name}</span>
                  {userItem.type === 'admin' && (
                    <TooltipInfo title="Administrador">
                      <IconCrown />
                    </TooltipInfo>
                  )}
                </NameContent>
              </td>
              <td>
                <Bagde
                  className={`${userItem.status === 'active' && 'active'} ${
                    userItem.status === 'exclused' && 'danger'
                  }`}
                >
                  {userStatus.find(item => item.id === userItem.status)?.label}
                </Bagde>
              </td>
              <td>
                {usersTypes.find(item => item.nameSlug === userItem.type)?.name}
              </td>
              {(user.type === 'admin' ||
                user.type === 'director' ||
                user.type === 'seller') && (
                <td width={138}>
                  <Actions>
                    <ActionsContent className="show">
                      <>
                        <IconEdit
                          onClick={() =>
                            handleCurrentUserId({
                              id: userItem.id,
                              email: userItem.email,
                              name: userItem.name,
                              type: userItem.type,
                            })
                          }
                        />
                        <IconDelete
                          height={30}
                          onClick={() =>
                            handleChangeUserStatus({
                              ids: [userItem.id],
                              statusUser: 'exclused',
                            })
                          }
                        />
                      </>

                      {userItem.status === 'exclused' && (
                        <IconRotate
                          height={25}
                          style={{ color: '#8D8585' }}
                          onClick={() =>
                            handleChangeUserStatus({
                              ids: [userItem.id],
                              statusUser: 'active',
                            })
                          }
                        />
                      )}
                    </ActionsContent>
                  </Actions>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [
    users,
    handleCheck,
    checks,
    user.type,
    handleCurrentUserId,
    handleChangeUserStatus,
  ]);

  return (
    <>
      <ModalAddUser
        isOpen={modalAddUserOpen}
        setIsOpen={toggleModalAddUser}
        userType={userType.filter(
          item => item.label !== 'Fornecedor de Serviço',
        )}
        handleInitialState={handleInitialState}
      />
      <ModalEditUser
        isOpen={modalEditUserOpen}
        setIsOpen={toggleCurrentUserId}
        userType={userType}
        handleInitialState={handleInitialState}
        userId={currentUserId}
      />
      <ModalCsv isOpen={modalImportCsvOpen} setIsOpen={toggleModalImportCsv} />
      <Container className="slideDown">
        <Title>
          <h1>Meu Time</h1>
          <ChoiceEllipse
            name="status"
            itens={[
              { id: '1', value: 'all', label: 'Todos' },
              { id: '2', value: 'active', label: 'Ativos' },
              { id: '3', value: 'inative', label: 'Inativos' },
            ]}
            defaultValue={status}
            handleStatus={handleStatus}
          />
        </Title>
        <Content>
          <Options>
            <OptionRight>
              <div className="search">
                <InputSearch
                  name="search"
                  placeholder="Pesquisar"
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className="select">
                <ReactSelect
                  name="select_usertype"
                  options={[
                    { label: 'Todos os usuário', value: 'all' },
                    ...userType,
                  ]}
                  defaultValue={select}
                  handleValue={handleSelect}
                />
              </div>
              {user.type !== 'seller_assistent' && (
                <DeleteButton
                  className={`${checks.length === 0 ? `hidden` : ``}`}
                  onClick={() =>
                    handleChangeUserStatus({
                      ids: checks,
                      statusUser: 'exclused',
                    })
                  }
                >
                  <IconTrash />
                </DeleteButton>
              )}
            </OptionRight>
            <OptionLeft style={{ flexDirection: 'row-reverse' }}>
              {user.type !== 'seller_assistent' && (
                <AddButton onClick={() => toggleModalAddUser()}>
                  <IconPersonAdd /> Novo membro
                </AddButton>
              )}

              {/* {user.type === 'admin' && (
                <ImportButton onClick={() => toggleModalImportCsv()}>
                  <IconDownload /> Importar CSV
                </ImportButton>
              )} */}
            </OptionLeft>
          </Options>
          <TableContent>
            <Tabs
              itens={[
                { id: 'tab-01', label: 'A-Z' },
                { id: 'tab-02', label: 'Mais recentes' },
                { id: 'tab-03', label: 'Lixeira' },
              ]}
              defaultValue={currentTab}
              handleTab={handleTab}
            />
            {loading ? (
              <div className="loading">
                <BeatLoader size={16} />
              </div>
            ) : (
              <div className="slideUp">
                <TabItem id="tab-01" defaultValue={currentTab}>
                  {renderUsers()}
                </TabItem>
                <TabItem id="tab-02" defaultValue={currentTab}>
                  {renderUsers()}
                </TabItem>
                <TabItem id="tab-03" defaultValue={currentTab}>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <CheckBoxAll
                            ids={users.map(item => item.id)}
                            handleCheckAll={handleCheck}
                          />
                        </th>
                        <th>Usuário</th>
                        <th style={{ fontStyle: 'italic' }}>Status</th>
                        <th>Tipo</th>
                        <th>Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.length <= 0 && (
                        <tr>
                          <td colSpan={5}>
                            <p style={{ textAlign: 'center' }}>
                              Nenhum item encontrado{' '}
                              <span role="img" aria-label="wow">
                                😮
                              </span>
                            </p>
                          </td>
                        </tr>
                      )}

                      {users.map(userItem => (
                        <tr key={`${userItem.id}-tab-02`} className="no-cursor">
                          <td>
                            <Checkbox
                              name="check"
                              handleCheck={handleCheck}
                              checks={checks}
                              id={`${userItem.id}`}
                            />
                          </td>
                          <td>
                            <NameContent>
                              <IconUserCircle />
                              <span>{userItem.name}</span>
                              {userItem.type === '1' && (
                                <TooltipInfo title="Administrador">
                                  <IconCrown />
                                </TooltipInfo>
                              )}
                            </NameContent>
                          </td>
                          <td>
                            <Bagde className="danger">Excluído</Bagde>
                          </td>
                          <td>
                            {
                              usersTypes.find(
                                item => item.nameSlug === userItem.type,
                              )?.name
                            }
                          </td>
                          <td>
                            <div className="link-options">
                              <button
                                type="button"
                                onClick={() =>
                                  handleChangeUserStatus({
                                    ids: [userItem.id],
                                    statusUser: 'active',
                                  })
                                }
                              >
                                Recuperar
                              </button>
                              {/* |
                            <button
                              type="button"
                              onClick={() =>
                                handleChangeUserStatus({
                                  ids: [userItem.id],
                                  statusUser: 'active',
                                })
                              }
                            >
                              Excluir
                            </button> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TabItem>
              </div>
            )}
          </TableContent>
        </Content>
      </Container>
    </>
  );
};

export default Users;
