import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import NavTop from '../../components/template/NavTop';
import { useCart } from '../../hooks/cart';
import { useToast } from '../../hooks/toast';
import { getProviderServiceById } from '../../services/api';
import { IconBookmarkYellow } from '../../styles/icons';
import IconUser from '../../assets/icons/icon-user-circle-lg.svg';
import { saveGenericLog } from '../../services/logs';
import { useAuth } from '../../hooks/auth';
import {
  ServiceContainer,
  Container,
  Main,
  MainContent,
  Service,
  ServiceImage,
  TextLink,
  IconContainer,
  ButtonAddToCart,
  SectionService,
} from './styles';

interface IParams {
  id: string;
}

interface IService {
  id: string;
  description: string;
  title: string;
  image: string;
  link: string;
  price: string;
}

interface IProviderServices {
  id: string;
  name: string;
  description: string;
  services: Array<IService>;
  imgLogoUrl: string;
}

const StoreServiceItem: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { addToCart } = useCart();
  const [loading, setLoading] = useState(false);
  const router: IParams = useParams();
  const [provider, setProvider] = useState<IProviderServices>();

  useEffect(() => {
    setLoading(true);
    const load = async (): Promise<void> => {
      try {
        const { data: res } = await getProviderServiceById(router.id);
        setProvider(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [addToast, router.id]);

  const handleAddCart = useCallback(
    (service: IService) => {
      addToCart({
        id: service.id,
        companyName: provider?.name,
        idService: service?.id,
        titleService: service.title,
        descriptionService: service.description,
        isService: true,
        priceInNumber: 0,
      });
    },
    [addToCart, provider],
  );

  const renderServicesProvider = useCallback(() => {
    return (
      <>
        {provider?.services.map(service => (
          <Service>
            {service.image ? (
              <ServiceImage
                style={{
                  backgroundImage: `url(${service.image})`,
                }}
              />
            ) : undefined}
            <div style={{ display: 'flex' }}>
              <ServiceContainer>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <p>{service.price}</p>
                {service.link ? (
                  <TextLink>
                    Link para mais detalhes:{' '}
                    <a href={service.link} target="__blank">
                      {service.link}
                    </a>
                  </TextLink>
                ) : undefined}
              </ServiceContainer>
              <IconContainer>
                <ButtonAddToCart
                  type="button"
                  onClick={() => {
                    handleAddCart(service);
                    saveGenericLog({
                      userId: user.id,
                      action: 'added_item_to_cart',
                    });
                  }}
                >
                  Adicionar ao carrinho
                </ButtonAddToCart>
              </IconContainer>
            </div>
          </Service>
        ))}
      </>
    );
  }, [handleAddCart, provider]);

  return (
    <>
      {loading ? (
        <div
          className="loading"
          style={{ textAlign: 'center', width: '100%', marginTop: '400px' }}
        >
          <BeatLoader size={16} />
        </div>
      ) : (
        <Container>
          <Main>
            <MainContent>
              <div className="navtop">
                <NavTop />
              </div>
              <div className="provider">
                {/* IMPLEMENTAR IMAGEM */}
                <img
                  src={provider?.imgLogoUrl || IconUser}
                  alt="Logo do fornecedor"
                />
                <div>
                  <h1>{provider?.name}</h1>
                  <div>
                    <div>
                      <p>{provider?.description}</p>
                      <p className="units">
                        <IconBookmarkYellow />{' '}
                        <strong>{provider?.services.length}</strong> Serviços
                      </p>
                    </div>
                    <div />
                  </div>
                </div>
              </div>
              <div>
                <h1 style={{ textAlign: 'center' }}>Serviços disponíveis</h1>

                <SectionService className="services">
                  {renderServicesProvider()}
                </SectionService>
              </div>
            </MainContent>
          </Main>
        </Container>
      )}
    </>
  );
};

export default StoreServiceItem;
