/* eslint-disable func-names */
import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container, Content, ContentForm, BottomForm } from './styles';
import AlertConfirm from '../../components/template/AlertConfirm';

import { setToken, setUser } from '../../services/auth';

import Input from '../../components/forms/Input';
import Buttom from '../../components/forms/Buttom';
import BackgroundSign from '../../components/template/BackgroundSign';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getVaidationErrors';
import { generateLmsKey, postSignIn } from '../../services/api';
import { useAuth } from '../../hooks/auth';

const SellerLmsKey: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [userCheck, setUserCheck] = useState(false);
  const [sellerId, setSellerId] = useState('');
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { signOut } = useAuth();

  window.onload = function () {
    localStorage.clear();
  };

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Campo Obrigatório')
            .email('Email inválido'),
          password: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { data: resSignIn } = await postSignIn(data);

        if (resSignIn.user.seller === undefined) {
          throw new Error(`teste`);
        }

        setToken(resSignIn.user.token);
        setUser(resSignIn);
        setSellerId(resSignIn.user.seller.id);

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Login realizado com sucesso!',
        });
        setUserCheck(true);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Opss ..',
          type: 'error',
          description:
            'Ocorreu um erro ao fazer login, verifique suas credencias.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleLmsKey = useCallback(
    async (id: string) => {
      try {
        const confirm = await AlertConfirm({
          title: 'Tem certeza que deseja gerar a chave de LMS?',
          text:
            'Sua chave anterior sera cancelada e todos os items relacionados à essa chave serão desvinculados. Para mais informações acesse a documentação clicando aqui.',
        });
        if (!confirm) {
          return;
        }
        const { data: response } = await generateLmsKey(id);

        const confirmPress = await AlertConfirm({
          title:
            'Sua chave LMS foi gerada, salve essa chave em algum lugar seguro pois ela só é visualizada quando é gerada',
          text: response.lms_key,
          type: 'lms',
        });

        localStorage.setItem('@aurea:seller:lms_key', response.lms_key);
        if (confirmPress) {
          signOut();
        }
      } catch (error) {
        addToast({
          title: 'Opss ..',
          type: 'error',
          description: 'Ocorreu um erro ao editar o seu perfil.',
        });
      }
    },
    [addToast, signOut],
  );

  return (
    <Container>
      <BackgroundSign />
      <Content className="slideDown">
        <ContentForm>
          <h1>Bem-vindo! Esta página tem o intuito de gerar a chave de LMS.</h1>
          <h2>
            Lembre-se que ao gerar uma nova chave, a antiga será excluída e
            todas as unidades relacionadas serão desvinculadas.
          </h2>
          {!userCheck ? (
            <Form onSubmit={handleSubmit} ref={formRef}>
              <Input
                name="email"
                label="Seu Email:"
                type="text"
                placeholder="email@dominio.com.br"
              />

              <Input
                name="password"
                label="Sua Senha:"
                type="password"
                placeholder="*************"
              />

              <BottomForm>
                <Buttom isLoading={loading} label="Entrar" />
              </BottomForm>
            </Form>
          ) : (
            <Buttom
              style={{ width: '100%' }}
              onClick={() => (sellerId ? handleLmsKey(sellerId) : '')}
              type="button"
              label="Gerar chave LMS"
            />
          )}
        </ContentForm>
      </Content>
    </Container>
  );
};

export default SellerLmsKey;
