/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../../components/template/Modal';
import ModalAddCourse from '../../components/template/ModalAddCourse';
import ModalEditCourse from '../../components/template/ModalEditCourse';
import ModalImportCourse from '../../components/template/ModalImportCourse';
import NavTop from '../../components/template/NavTop';
import {
  Body,
  Main,
  MainContent,
} from '../../components/template/SellerTemplate/styles';
import {
  AddButton,
  OptionLeft,
  OptionRight,
  Options,
  Title,
} from '../../components/template/TemplateManager/styles';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import {
  deleteProvidersCourse,
  deleteSellersCourse,
  getProvidersCourses,
  getSellersCourses,
} from '../../services/api';
import { saveManageCoursesLog } from '../../services/logs';
import {
  IconAddCircle,
  IconBookmarkYellow,
  IconDownload,
  IconEdit,
  IconSubjectTrash,
} from '../../styles/icons';
import { Container, Content, Holder, ModalContent } from './styles';

interface ICourse {
  name: string;
  id: string;
  itemsCount: number;
  courseItems: {
    courseId: string;
    id: string;
    order: number;
    subjectId: string;
  }[];
}
interface ICourseToEdit {
  name: string;
  id: string;
}

const Courses: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [courses, setCourses] = useState<ICourse[]>();
  const [courseToEdit, setCourseToEdit] = useState<ICourseToEdit>();
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [courseIdToRemove, setCourseIdToRemove] = useState<string>();

  const [modalAddCourseOpen, setModalAddCourseOpen] = useState(false);
  const [modalImportCourseOpen, setModalImportCourseOpen] = useState(false);
  const [modalEditCourseOpen, setModalEditCourseOpen] = useState(false);

  const toggleModalAddCourse = useCallback(() => {
    setModalAddCourseOpen(!modalAddCourseOpen);
  }, [modalAddCourseOpen]);

  const toggleModalImportCourse = useCallback(() => {
    setModalImportCourseOpen(!modalImportCourseOpen);
  }, [modalImportCourseOpen]);

  const toggleModalEditCourse = useCallback(() => {
    setModalEditCourseOpen(!modalEditCourseOpen);
  }, [modalEditCourseOpen]);

  const handleEditCourse = (id: string, name: string) => {
    setCourseToEdit({ id, name });
    toggleModalEditCourse();
  };

  // REFRESH ===========
  const [refresh, setRefresh] = useState(false);

  // CLEAR FILTER ===========
  const clearFilter = useCallback(() => {
    setRefresh(!refresh);
  }, [refresh]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        if (user.type.includes('seller')) {
          const { data } = await getSellersCourses();
          setCourses(data);
        } else if (user.type === 'provider') {
          const { data } = await getProvidersCourses();
          setCourses(data);
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    };

    load();
  }, [addToast, refresh, user.type]);

  const handleRemoveSubject = useCallback(async () => {
    if (courseIdToRemove) {
      try {
        if (user.type === 'seller' || user.type === 'seller_content_manager') {
          await deleteSellersCourse(courseIdToRemove);
          clearFilter();
        } else if (user.type === 'provider') {
          await deleteProvidersCourse(courseIdToRemove);
          clearFilter();
        }

        saveManageCoursesLog({
          userId: user.id,
          action: 'removed_course',
          courseId: courseIdToRemove,
        });

        addToast({
          title: 'Sucesso!',
          description: 'Curso removido com sucesso!',
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    }
    setIsOpenDelete(!isOpenDelete);
  }, [courseIdToRemove, isOpenDelete, user.type, addToast, clearFilter]);

  const handleOpenModalDelete = useCallback(
    (id?: string) => {
      if (id) setCourseIdToRemove(id);
      setIsOpenDelete(!isOpenDelete);
    },
    [isOpenDelete],
  );

  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <ModalAddCourse
              isOpen={modalAddCourseOpen}
              setIsOpen={toggleModalAddCourse}
              clearFilter={clearFilter}
            />
            {user.type.includes('seller') && (
              <ModalImportCourse
                isOpen={modalImportCourseOpen}
                setIsOpen={toggleModalImportCourse}
                clearFilter={clearFilter}
              />
            )}
            {courseToEdit && (
              <ModalEditCourse
                isOpen={modalEditCourseOpen}
                setIsOpen={toggleModalEditCourse}
                clearFilter={clearFilter}
                course={courseToEdit}
              />
            )}
            {courseIdToRemove && (
              <Modal
                isOpen={isOpenDelete}
                setIsOpen={() => handleOpenModalDelete()}
              >
                <ModalContent cancel>
                  <h1 className="modalTitle">
                    Tem certeza que deseja remover este curso?
                  </h1>
                  <section>
                    <button onClick={() => handleRemoveSubject()} type="button">
                      SIM
                    </button>
                    <button
                      onClick={() => handleOpenModalDelete()}
                      className="cancel"
                      type="button"
                    >
                      NÃO
                    </button>
                  </section>
                </ModalContent>
              </Modal>
            )}
            <Container>
              <Options>
                <OptionRight>
                  <Title>
                    <h1>Cursos</h1>
                  </Title>
                </OptionRight>
                <OptionLeft style={{ flexDirection: 'row-reverse' }}>
                  {user.type.includes('seller') && (
                    <AddButton onClick={toggleModalImportCourse}>
                      <IconDownload /> Importar Curso
                    </AddButton>
                  )}
                  <AddButton onClick={toggleModalAddCourse}>
                    <IconAddCircle /> Novo Curso
                  </AddButton>
                </OptionLeft>
              </Options>
              <Content>
                <section>
                  {courses?.map(course => (
                    <article key={`${course.id}-tab-01`} className="slideUp">
                      <header>
                        {user.type !== 'seller_assistent' && (
                          <>
                            <button
                              type="button"
                              onClick={() => handleOpenModalDelete(course.id)}
                            >
                              <IconSubjectTrash />
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                handleEditCourse(course.id, course.name)
                              }
                              style={{ right: '20px' }}
                            >
                              <IconEdit style={{ width: '15px' }} />
                            </button>
                          </>
                        )}

                        <h1>{course.name}</h1>
                        <p className="units">
                          <IconBookmarkYellow />{' '}
                          {user.type.includes('seller') ? (
                            <strong>{course.itemsCount} </strong>
                          ) : (
                            <strong>{course.courseItems.length} </strong>
                          )}
                          Disciplinas
                        </p>
                      </header>
                      <main />
                      <footer>
                        <Link to={`/seller/course/${course.id}`}>
                          Ver curso
                        </Link>
                      </footer>
                    </article>
                  ))}
                </section>
              </Content>
            </Container>
          </Body>
        </MainContent>
      </Main>
    </Holder>
  );
};

export default Courses;
