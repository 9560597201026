import React, { createContext, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { saveGenericLog } from '../services/logs';
import {
  postSignIn,
  IUser,
  IUserResponse,
  IUserSignIn,
  setToken,
  setUser,
  setLmsKey,
  logout,
  getToken,
  getUser,
} from '../services/auth';

interface IUpdateUser {
  name?: string;
  email?: string;
  avatar?: string | null;
}

interface IAuthContextState {
  signIn(credentials: IUserSignIn): Promise<IUser>;
  signOut(): void;
  user: IUser;
  signed: boolean;
  updateUser(dataUpdate: IUpdateUser): void;
}

const AuthContext = createContext<IAuthContextState>({} as IAuthContextState);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [data, setData] = useState<IUserResponse>((): IUserResponse => {
    const token = getToken();
    const user = getUser();

    if (token && user) {
      return { token, user };
    }

    return {} as IUserResponse;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const resSignIn = await postSignIn({ email, password });

    if (!resSignIn) {
      throw new Error();
    }

    const { token, user } = resSignIn;
    setToken(token);
    setUser(user);

    if (user.seller?.lms_key) {
      setLmsKey(user.seller?.lms_key);
    }

    await saveGenericLog({ userId: user.id, action: 'logged' });

    setData({ token, user });
    return user;
  }, []);

  const signOut = useCallback((): void => {
    logout();
    history.push('/');
    setData({} as IUserResponse);
  }, [history]);

  const updateUser = useCallback(
    (dataUpdate: IUpdateUser) => {
      let avatar: string | undefined | null;
      if (dataUpdate.avatar === undefined) {
        avatar = data.user.avatar;
      } else if (dataUpdate.avatar === null) {
        avatar = null;
      } else {
        avatar = dataUpdate.avatar;
      }
      setUser({
        ...data.user,
        email: dataUpdate.email ? dataUpdate.email : data.user.email,
        name: dataUpdate.name ? dataUpdate.name : data.user.name,
        avatar,
      });

      const getUserUpdate = getUser();
      if (getUserUpdate) {
        setData({
          ...data,
          user: getUserUpdate,
        });
      }
    },
    [data],
  );
  return (
    <AuthContext.Provider
      value={{
        signed: !!data.user,
        user: data.user,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContextState => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
