/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { BeatLoader } from 'react-spinners';
import { useHistory, Link } from 'react-router-dom';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { ImSad } from 'react-icons/im';
import { FaTrash } from 'react-icons/fa';
import { BsBookmarkFill } from 'react-icons/bs';
import NavTop from '../../components/template/NavTop';
import UserImage from '../../assets/icons/icon-user-circle.svg';
import {
  Container,
  Main,
  MainContent,
  Body,
  CardContainer,
  RightSide,
  ContentContainer,
  CheckoutContent,
  Total,
  Titles,
  FormRadio,
  ButtonPay,
  EmptyCart,
  HeaderTitle,
  CheckoutContainerEmpty,
  ModalContent,
} from './styles';
import Dropdown from '../../components/events/Dropdown';
import { useCart } from '../../hooks/cart';
import Modal from '../../components/template/Modal';
import { IProviders } from '../Store';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import {
  getPaymentMethods,
  getSellerDataById,
  postCreateRent,
  postServiceCart,
} from '../../services/api';
import { saveGenericLog } from '../../services/logs';

interface IPaymentMethod {
  id: string;
  description: string;
}

interface ISeller {
  loaded: boolean;
  id: string;
  name: string;
  ownerId: string;
  cnpj: string;
  planId: string;
}

const Cart: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const { removeToCart, cartItems, clearCart } = useCart();

  const [totalToPay, setTotalToPay] = useState<number>();
  const [selectPay, setSelectPay] = useState('');
  const [payAvailable, setPayAvailable] = useState(false);
  const [dropdownNotification, setDropdownNotification] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<IProviders>();

  // SELLERS ===========
  const [seller, setSeller] = useState<ISeller>({} as ISeller);
  useEffect(() => {
    const load = async (): Promise<void> => {
      if (user.type === 'seller' && user.seller) {
        try {
          const { data: res } = await getSellerDataById(user.seller.id);
          setSeller(res);
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      }
    };
    load();
  }, [user, addToast]);

  // CONTINUES

  useEffect(() => {
    if (selectPay) {
      setPayAvailable(true);
    }
    const { total } = cartItems.reduce(
      (acumulator, items) => {
        acumulator.total += Number(items.priceInNumber);
        return acumulator;
      },
      {
        total: 0,
      },
    );
    setTotalToPay(total);
  }, [selectPay, cartItems]);

  // SEND FORM =====

  const handleSubmit = useCallback(async () => {
    if (!selectPay) {
      setDropdownNotification(true);
    } else {
      try {
        const services = cartItems
          .filter(items => items.isService && items.isService === true)
          .map(cartItem => cartItem.idService);

        if (
          services.length !== 0 &&
          cartItems.map(item => item.isService === true)
        ) {
          clearCart();
          addToast({
            type: 'success',
            title:
              'Solicitação enviada com sucesso! Em breve entraremos em contato.',
          });
          await postServiceCart(services);
          await saveGenericLog({
            userId: user.id,
            action: 'payment_requested',
          });

          return history.push('/seller/rents');
        }
        const providerIds = cartItems
          .filter(items => items.isService !== true)
          .map(cartItem => cartItem.id);
        const createRent = {
          sellerId: seller.id,
          paymentMethod: selectPay,
          providerIds,
        };
        await postCreateRent(createRent);
        clearCart();
        addToast({
          type: 'success',
          title:
            'Solicitação enviada com sucesso! Em breve entraremos em contato.',
        });

        await saveGenericLog({
          userId: user.id,
          action: 'payment_requested',
        });

        history.push('/seller/rents');
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    }
  }, [selectPay, seller, cartItems, addToast, clearCart, history]);

  const handleKeepShopping = useCallback(() => {
    history.push('/seller/store');
  }, [history]);

  const formatPrice = useMemo(() => {
    if (totalToPay) {
      return totalToPay?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    return totalToPay?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }, [totalToPay]);

  const handleOpenModal = useCallback(
    (item: IProviders) => {
      setItemToRemove(item);
      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  const handleRemoveToCart = useCallback(
    (item: IProviders) => {
      removeToCart(item);
      handleOpenModal(item);
    },
    [removeToCart, handleOpenModal],
  );

  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>(
    [] as IPaymentMethod[],
  );
  useEffect(() => {
    async function load() {
      const { data: result } = await getPaymentMethods();
      setPaymentMethods(result);
    }
    load();
  }, [setPaymentMethods]);

  return (
    <Container>
      {itemToRemove && (
        <Modal isOpen={isOpen} setIsOpen={() => handleOpenModal(itemToRemove)}>
          <ModalContent cancel>
            <h1 className="modalTitle">
              Tem certeza que deseja remover o item {itemToRemove.companyName}?
            </h1>
            <section>
              <button
                onClick={() => handleRemoveToCart(itemToRemove)}
                type="button"
              >
                {' '}
                SIM
              </button>
              <button
                onClick={() => handleOpenModal(itemToRemove)}
                className="cancel"
                type="button"
              >
                NÂO
              </button>
            </section>
          </ModalContent>
        </Modal>
      )}
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <div className="slideDown">
              <HeaderTitle className="bread-crumb">
                <ul>
                  <li>
                    <Link to="/seller/store/">Loja</Link>
                  </li>
                  <li>Carrinho</li>
                </ul>
              </HeaderTitle>
              <h1>Carrinho</h1>
              {cartItems &&
                cartItems.map(items =>
                  items.isService ? (
                    <CardContainer key={items.id}>
                      <img
                        src={items.imgLogoUrl ? items.imgLogoUrl : UserImage}
                        alt=""
                      />
                      <ContentContainer>
                        <h2>{items.companyName}</h2>
                        <h3>{items.titleService}</h3>
                        <span>
                          Fornecedor {items.isPrimium ? 'Premium' : 'Basic'}
                        </span>
                        <p className="descriptionService">
                          {items.descriptionService}
                        </p>
                        <section>
                          <div>
                            <p>
                              <BsBookmarkFill size={15} />
                              <strong>Serviço</strong>
                            </p>
                            <button
                              onClick={() => handleOpenModal(items)}
                              type="button"
                            >
                              <FaTrash size={10} />
                              Remover do carrinho
                            </button>
                          </div>
                          <p className="warnPriceService">
                            <strong>Atenção: </strong>O preço será de acordo com
                            as instruções que enviaremos por email
                          </p>
                        </section>
                      </ContentContainer>
                    </CardContainer>
                  ) : (
                    <CardContainer key={items.id}>
                      <img
                        src={items.imgLogoUrl ? items.imgLogoUrl : UserImage}
                        alt=""
                      />
                      <ContentContainer>
                        <h2>{items.companyName}</h2>
                        <span>
                          Fornecedor {items.isPrimium ? 'Premium' : 'Basic'}
                        </span>
                        <section>
                          <div>
                            <p>
                              <BsBookmarkFill size={15} />
                              <strong>{items.provider_total_items}</strong>{' '}
                              unidades
                            </p>
                            <button
                              onClick={() => handleOpenModal(items)}
                              type="button"
                            >
                              <FaTrash size={10} />
                              Remover do carrinho
                            </button>
                          </div>
                          <p>
                            {items.plan_value}
                            <span>/mês</span>
                          </p>
                        </section>
                      </ContentContainer>
                    </CardContainer>
                  ),
                )}
              {cartItems.length === 0 && (
                <EmptyCart>
                  <AiOutlineShoppingCart color="#E0DEDE" size={70} />
                  <h3 className="emptyCartMsg">
                    O seu carrinho de compras está vazio
                  </h3>
                  <p>Você ainda não adicionou nenhum produto ao carrinho</p>
                </EmptyCart>
              )}
              <div className="container">
                <div className="empty">
                  <button
                    onClick={handleKeepShopping}
                    className="continues"
                    type="button"
                  >
                    Continuar comprando
                  </button>
                </div>
              </div>
            </div>
          </Body>
        </MainContent>
      </Main>
      <RightSide className="slideLeft">
        <h1>Checkout</h1>
        {cartItems &&
          cartItems.map(items => (
            <CheckoutContent key={items.id}>
              <p>{items.companyName}</p>
              <span>{items.plan_value}</span>
            </CheckoutContent>
          ))}
        {cartItems.length === 0 ? (
          <CheckoutContainerEmpty>
            <ImSad color="#E0DEDE" size={60} />
            <p>Nenhum item adicionado ao carrinho</p>
          </CheckoutContainerEmpty>
        ) : (
          <Total>
            <h3>TOTAL</h3>
            <span>{formatPrice}</span>
          </Total>
        )}
        {cartItems.length !== 0 && (
          <>
            <Titles>
              <h2>Formas de Pagamento</h2>
              <h3>Escolha a forma de pagamento</h3>
            </Titles>
            <FormRadio>
              {paymentMethods.map((paymentMethod, index) => (
                <section key={paymentMethod.id}>
                  <input
                    type="radio"
                    name="bank"
                    onChange={() => setSelectPay(paymentMethod.id)}
                    id={`paymentmethod-${index}`}
                  />
                  <label htmlFor={`paymentmethod-${index}`}>
                    {paymentMethod.description}
                  </label>
                </section>
              ))}
              {paymentMethods.length === 0 && (
                <div className="global-loading">
                  <BeatLoader size={16} />
                </div>
              )}
            </FormRadio>
            <section className="pay">
              {payAvailable ? (
                <ButtonPay payAvailable={payAvailable} onClick={handleSubmit}>
                  Solicitar Pagamento
                </ButtonPay>
              ) : (
                <>
                  <ButtonPay onClick={handleSubmit} payAvailable={payAvailable}>
                    Solicitar Pagamento
                  </ButtonPay>
                  <Dropdown
                    handleDropdown={setDropdownNotification}
                    setIsOpen={dropdownNotification}
                  >
                    <main
                      hidden={!dropdownNotification}
                      style={{ width: '300px' }}
                    >
                      <header className="alert">
                        <p>
                          Selecione uma forma de pagamento antes de continuar!
                        </p>
                      </header>
                    </main>
                  </Dropdown>
                </>
              )}
            </section>
            <p>
              Após solicitar o pagamento, seu pedido entrará em análise e em até
              24 horas você receberá as informações de pagamento.
            </p>
          </>
        )}
      </RightSide>
    </Container>
  );
};

export default Cart;
