import React, { useCallback, useState, useEffect } from 'react';
import { List, arrayMove } from 'react-movable';
import { BeatLoader } from 'react-spinners';
import { Link, useParams } from 'react-router-dom';

import {
  Holder,
  Container,
  Content,
  Title,
  ModalContent,
  Article,
} from './styles';

import {
  OptionLeft,
  OptionRight,
  Options,
} from '../../components/template/TemplateManager/styles';

import {
  Main,
  MainContent,
  Body,
} from '../../components/template/SellerTemplate/styles';

import NavTop from '../../components/template/NavTop';
import Modal from '../../components/template/Modal';
import AlertConfirm from '../../components/template/AlertConfirm';
import InputSearch from '../../components/forms/InputSearch';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { IconAddCircle, IconDragger, IconShare } from '../../styles/icons';

import {
  deleteSellerSubjectItem,
  getListSellerSubjectItems,
  putOrdenateSellerSubjectItem,
  getProviderSubjectItem,
  putOrdenateProviderSubjectItem,
  deleteProviderSubjectItem,
  getSellerDataById,
  getSellerIdByContentManagerUserId,
} from '../../services/api';

export interface ISubjectItem {
  available?: true;
  companyName?: string;
  order: number;
  providerId?: string;
  subjectItemId: string;
  title: string;
  itemContentId: string;
  itemId: string;
  item?: {
    id: string;
  };
}

interface IParams {
  id: string;
}

const Subject: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const { id: subjectId } = useParams<IParams>();
  const [loading, setLoading] = useState(true);

  // SEARCH ===========
  const [search, setSearch] = useState('');

  // CLEAR FILTER ===========
  const clearFilter = useCallback(() => {
    setSearch('');
  }, []);

  // PROVIDERS ===========
  const [update, setUpdate] = useState(true);

  const [subjectName, setSubjectName] = useState<string>('');
  const [subjectitems, setSubjectItems] = useState<ISubjectItem[]>([]);
  const handleSetSubjectItems = useCallback((subjectitem: ISubjectItem[]) => {
    setSubjectItems(
      subjectitem.map(item => {
        return {
          ...item,
        };
      }),
    );
  }, []);

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true);

      try {
        handleSetSubjectItems([]);

        if (user.type.includes('seller')) {
          const { data } = await getListSellerSubjectItems(subjectId);
          const { subject, subjectItems } = data;

          setSubjectName(subject.name);
          handleSetSubjectItems(subjectItems.sort((a, b) => a.order - b.order));
        } else if (user.type === 'provider') {
          const { data } = await getProviderSubjectItem(subjectId);
          const { name, subjectItems } = data;

          setSubjectName(name);
          handleSetSubjectItems(subjectItems.sort((a, b) => a.order - b.order));
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }

      setLoading(false);
    };

    load();
  }, [subjectId, user, addToast, handleSetSubjectItems]);

  // REMOVE SUBJECT ======
  const [isOpen, setIsOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<false | string>(false);

  const handleRemoveSubject = useCallback(async () => {
    setIsOpen(false);

    if (!user.type.includes('seller')) {
      addToast({
        title: 'Ops...',
        type: 'error',
        description: 'Você não tem permissão para realizar esta operação.',
      });

      return false;
    }

    if (itemToRemove !== false) {
      try {
        if (user.type.includes('seller')) {
          await deleteSellerSubjectItem(itemToRemove);
        }

        if (user.type === 'provider') {
          await deleteProviderSubjectItem(subjectId, itemToRemove);
        }

        setUpdate(!update);
        clearFilter();

        handleSetSubjectItems([]);

        if (user.type.includes('seller')) {
          const { data } = await getListSellerSubjectItems(subjectId);
          const { subject, subjectItems } = data;

          setSubjectName(subject.name);
          handleSetSubjectItems(subjectItems.sort((a, b) => a.order - b.order));
        } else if (user.type === 'provider') {
          const { data } = await getProviderSubjectItem(subjectId);
          const { name, subjectItems } = data;

          setSubjectName(name);
          handleSetSubjectItems(subjectItems.sort((a, b) => a.order - b.order));
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    }
  }, [
    user,
    addToast,
    itemToRemove,
    clearFilter,
    setUpdate,
    update,
    subjectId,
    handleSetSubjectItems,
  ]);

  const handleOpenModal = useCallback(
    (itemId?: string) => {
      if (itemId) setItemToRemove(itemId);
      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  // HANDLE DROP ======
  const handleDrop = useCallback(
    async ({ oldIndex, newIndex }) => {
      if (user.type === 'seller_assistent') {
        return false;
      }

      const newOrder = arrayMove(subjectitems, oldIndex, newIndex);
      setSubjectItems(newOrder);

      try {
        if (user.seller) {
          const subjectItemsFormatted = newOrder.map((itemOrder, index) => {
            return {
              subjectItemId: itemOrder.subjectItemId,
              order: index,
            };
          });

          await putOrdenateSellerSubjectItem(subjectItemsFormatted);
        } else if (user.provider) {
          const subjectItemsFormatted = newOrder.map((itemOrder, index) => {
            return {
              id: itemOrder.subjectItemId,
              order: index,
            };
          });

          await putOrdenateProviderSubjectItem(
            subjectItemsFormatted,
            subjectId,
          );
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    },
    [subjectitems, user, subjectId, addToast],
  );

  const handleShare = useCallback(async (itemId: string) => {
    let lmsKey = localStorage.getItem('@aurea:seller:lms_key');

    if (!lmsKey) {
      const userDataByLocalStorage = localStorage.getItem('@aurea:user');
      let userId = null;

      if (userDataByLocalStorage) {
        const userDataInJson = JSON.parse(userDataByLocalStorage);
        userId = userDataInJson.id;
      }
      if (userId) {
        const { data } = await getSellerIdByContentManagerUserId(userId);

        lmsKey = data.lms_key;
      }
    }

    if (lmsKey) {
      lmsKey.replace(/['"]+/g, '');
      await AlertConfirm({
        title: 'Este link serve para compartilhar com os alunos o seu conteúdo',
        type: 'shareLink',
        subjectId: itemId,
        lmsKey,
      });
    }
  }, []);

  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            {itemToRemove && (
              <Modal isOpen={isOpen} setIsOpen={() => handleOpenModal()}>
                <ModalContent cancel>
                  <h1 className="modalTitle">
                    Tem certeza que deseja remover a unidade?
                  </h1>
                  <section>
                    <button onClick={() => handleRemoveSubject()} type="button">
                      SIM
                    </button>
                    <button
                      onClick={() => handleOpenModal()}
                      className="cancel"
                      type="button"
                    >
                      NÃO
                    </button>
                  </section>
                </ModalContent>
              </Modal>
            )}
            <Container className="slideDown">
              <Content>
                <Options>
                  <OptionRight className="clear">
                    <Title>
                      <div className="bread-crumb">
                        <ul>
                          <li>
                            <Link to="/seller/manage-subjects">
                              Disciplinas
                            </Link>
                          </li>
                          <li>{subjectName}</li>
                        </ul>
                      </div>
                      <h1>Disciplina</h1>
                      <h2>{subjectName}</h2>
                    </Title>
                    <div className="search mT" style={{ display: 'none' }}>
                      <InputSearch
                        name="search"
                        onChange={e => setSearch(e.target.value)}
                        placeholder="Pesquisar"
                        value={search}
                      />
                    </div>
                  </OptionRight>
                  <OptionLeft style={{ flexDirection: 'row-reverse' }}>
                    <Link to="/seller/rented-units">
                      <IconAddCircle /> Nova unidade
                    </Link>
                  </OptionLeft>
                </Options>
                <section>
                  {loading && (
                    <div className="loading">
                      <BeatLoader size={16} />
                    </div>
                  )}

                  {!loading && (
                    <List
                      values={subjectitems}
                      onChange={handleDrop}
                      renderList={renderList => (
                        <div className="slideUp dropper" {...renderList.props}>
                          {renderList.children}
                        </div>
                      )}
                      renderItem={renderItem => (
                        <Article
                          key={`${renderItem.value.subjectItemId}-tab-01`}
                          className="availableItem"
                          {...renderItem.props}
                        >
                          <header>
                            <strong>{Number(renderItem.index) + 1}</strong>
                          </header>
                          <main>
                            <div>
                              <h1>{renderItem.value.title}</h1>
                              {renderItem.value.companyName ? (
                                <p>{renderItem.value.companyName}</p>
                              ) : undefined}
                            </div>
                          </main>
                          <footer>
                            {user.seller ? (
                              <Link
                                to={`/seller/subject/${subjectId}/item/${renderItem.value.itemContentId}/${renderItem.value.subjectItemId}`}
                              >
                                Ver unidade
                              </Link>
                            ) : (
                              <Link
                                to={`/studio/${renderItem.value.itemContentId}`}
                              >
                                Ver unidade
                              </Link>
                            )}
                            {user.type !== 'seller_assistent' && (
                              <>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleOpenModal(
                                      renderItem.value.subjectItemId,
                                    )
                                  }
                                >
                                  Remover unidade
                                </button>
                                <button
                                  className="shareButton"
                                  type="button"
                                  onClick={() =>
                                    handleShare(renderItem.value.itemId)
                                  }
                                >
                                  <IconShare style={{ margin: '0px 10px' }} />
                                </button>
                              </>
                            )}
                          </footer>
                          {user.type !== 'seller_assistent' && (
                            <div className="dragger">
                              <span>
                                <IconDragger />
                              </span>
                            </div>
                          )}
                        </Article>
                      )}
                    />
                  )}
                  {subjectitems.length <= 0 && !loading && (
                    <div className="loading">
                      <p>Nenhum item encontrado.</p>
                    </div>
                  )}
                </section>
              </Content>
            </Container>
          </Body>
        </MainContent>
      </Main>
    </Holder>
  );
};

export default Subject;
