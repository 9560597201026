/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { FiBell, FiLogOut, FiFileText } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { ptBR } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import { ImWhatsapp } from 'react-icons/im';
import { ImBubbles } from 'react-icons/im';
import { AiOutlineForm } from 'react-icons/ai';
import Modal from '../Modal';

import { firebase } from '../../../services/firebaseConnection';
import { useAuth } from '../../../hooks/auth';
import { saveGenericLog } from '../../../services/logs';
import {
  Container,
  NotificationsBox,
  Notification,
  NotificationsContent,
  ModalContent,
  File,
  RowGroup,
  ContactDropDown,
} from './styles';
import { generateId } from '../../../utils/generateId';

interface INotification {
  id: string;
  text: string;
  read: boolean;
  createdAt: number;
  parsedData: string;
}

const NavTop: React.FC = () => {
  const history = useHistory();
  const { user, signOut } = useAuth();
  const userAvatar =
    user.avatar || 'https://cdn-icons-png.flaticon.com/512/149/149071.png';

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [isOpenOrSuspendModal, setIsOpenOrSuspendModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModalTerms = () => {
    setIsOpenOrSuspendModal(!isOpenOrSuspendModal);
  };
  const handleOpenModalService = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleGetData = useCallback(() => {
    const today = new Date();
    today.setDate(today.getDate() - 5);

    const orderBy = (itemA: INotification, itemB: INotification) => {
      if (itemA.createdAt > itemB.createdAt) return -1;
      if (itemA.createdAt < itemB.createdAt) return 1;
      return 0;
    };

    firebase
      .database()
      .ref(`aurea/notifications/user/${user.id}`)
      .orderByChild('createdAt')
      .startAt(today.getTime())
      .on('value', snapshot => {
        const result = snapshot.val() || {};
        const parsedArray = Object.entries(result)
          .map(([uuid, value]) => {
            const parsed = value as INotification;
            parsed.id = uuid;

            parsed.parsedData = formatDistanceToNow(
              new Date(parsed.createdAt),
              { locale: ptBR },
            );

            return parsed;
          })
          .sort(orderBy);

        setNotifications([...parsedArray]);
      });
  }, [user]);

  const handleUpdateNotification = useCallback(
    async (notification_ID?: string) => {
      let sourceData = notifications.filter(item => item.read);

      if (notification_ID) {
        sourceData = sourceData.filter(item => item.id === notification_ID);
      }

      sourceData.forEach(item => {
        firebase
          .database()
          .ref(`aurea/notifications/user/${user.id}/${item.id}`)
          .update({ read: true });
      });
    },
    [user, notifications],
  );

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    const eventListener = element => {
      const target = document.getElementById(
        'modalNotifications',
      ) as HTMLElement;

      if (
        !target.contains(element.target) &&
        target.classList.contains('open')
      ) {
        target.classList.remove('open');
      }
    };

    document.addEventListener('click', eventListener);
    return () => document.removeEventListener('click', eventListener);
  }, []);

  const handleOpenNotifications = useCallback(() => {
    const target = document.getElementById('modalNotifications');
    setTimeout(() => target?.classList.add('open'), 10);
  }, []);

  return (
    <Container>
      <Modal isOpen={isModalOpen} setIsOpen={() => setIsModalOpen(false)}>
        <ContactDropDown>
          <h1>Atendimento</h1>
          <p>
            Para um atendimento rápido e eficiente, você pode preencher nosso
            formulário de atendimento ou nos contatar pelo WhatsApp, basta
            clicar no link ou escanear o QR Code.
          </p>
          <RowGroup style={{ marginTop: '20px' }}>
            <File>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSenbnF3WTqXp_4UvCvj_P6jbpEZBMWnyHytlU6YwWGrRyE5jw/viewform"
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineForm size={25} />
                &nbsp; Formulário de Atendimento
              </a>
            </File>
            <File>
              <a
                href="https://api.whatsapp.com/message/CCP4MPSKKVECF1?autoload=1&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <ImWhatsapp size={24} />
                &nbsp; Whatsapp(web)
              </a>
            </File>
          </RowGroup>
          <div className="imagem" style={{ marginTop: '35px' }}></div>
          <section style={{ marginTop: '15px' }}>
            <button
              onClick={() => handleOpenModalService()}
              className="cancel"
              type="button"
            >
              Sair
            </button>
          </section>
        </ContactDropDown>
      </Modal>
      <span>
        <button type="button" onClick={() => handleOpenModalService()}>
          <ImBubbles size={20} />
          Atendimento
        </button>
      </span>

      <Modal
        isOpen={isOpenOrSuspendModal}
        setIsOpen={() => handleOpenModalTerms()}
      >
        <ModalContent cancel>
          <h1 className="modalTitle">Termos Áurea</h1>
          <RowGroup>
            <File>
              <a
                href="https://aurea-files.s3.amazonaws.com/provider/gallery/0127898b-becd-4e89-9e53-181dd5b5ee10/1670260522529%20-%20AUREA%20-%20Termo%20de%20Pol%C3%ADtica%20de%20Privacidade.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Termo de Política de Privacidade
              </a>
            </File>

            <File>
              <a
                href="https://aurea-files.s3.amazonaws.com/provider/gallery/0127898b-becd-4e89-9e53-181dd5b5ee10/1670261412034%20-%20AUREA%20-%20Termo%20de%20Uso%20da%20Plataforma%20Educacional%20Aurea.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Termo de Uso da Plataforma Educacional Aurea
              </a>
            </File>
          </RowGroup>

          <section style={{ marginTop: '20px' }}>
            <button
              onClick={() => handleOpenModalTerms()}
              className="cancel"
              type="button"
            >
              Sair
            </button>
          </section>
        </ModalContent>
      </Modal>
      <span className="notification" onClick={handleOpenNotifications}>
        <FiBell size={20} />
        {notifications.filter(item => item.read).length > 0 && <label />}

        <NotificationsBox id="modalNotifications">
          <header>
            <h2>Notificações</h2>
            <button type="button" onClick={() => handleUpdateNotification()}>
              Marcar todas como lidas
            </button>
          </header>
          <NotificationsContent>
            {notifications.length === 0 ? (
              <Notification read={false} style={{ textAlign: 'center' }}>
                Não há notificações no momento
              </Notification>
            ) : (
              notifications.map(item => (
                <Notification
                  key={generateId()}
                  onClick={() => handleUpdateNotification(item.id)}
                  read={item.read}
                >
                  <label>
                    <p dangerouslySetInnerHTML={{ __html: item.text }} />
                    <span />
                  </label>
                  <strong>há {item.parsedData}</strong>
                </Notification>
              ))
            )}
          </NotificationsContent>
        </NotificationsBox>
      </span>
      <span onClick={() => history.push('/dashboard/profile')}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <small>Olá,</small>
          <strong>{user.name}</strong>
        </div>
        <img src={userAvatar} alt={user.name} />
      </span>
      <span>
        <button type="button" onClick={() => handleOpenModalTerms()}>
          <FiFileText size={22} />
          termos
        </button>
      </span>

      <span>
        <button
          type="button"
          onClick={async () => {
            signOut();
            await saveGenericLog({ userId: user.id, action: 'logged_out' });
          }}
        >
          <FiLogOut size={22} />
          sair
        </button>
      </span>
    </Container>
  );
};

export default NavTop;
